import { default as EventApi } from "@/services/api/requests/event";
import { default as CourseApi } from "@/services/api/requests/course";
import { default as PlatformUserApi } from "@/services/api/requests/platformUser";
import { CourseContentModel } from "@/store/modules/Course/model";
import constants from "@/utils/constants";
import Utils from "@/utils";
import store from "@/store";
import { EventContentModel } from "@cruciallearning/puddle/models/event";
import UrlBuilder from "@cruciallearning/puddle/urlbuilder";
import PopupUtil from "@cruciallearning/puddle/popup";
import { emptyOrBlankToNull, isEmptyOrBlank } from "@cruciallearning/puddle/utils";

class SdiUtils {
  /** default window target for SDI links */
  private static WIN_TARGET = "win-sdi-clp";

  /** user-facing error message to display in {@link #openSdiWindow} when destination URL could not be computed. */
  private static ERR_MSG_BAD_LINK = "Something went wrong trying to open this link.";

  /** user-facing error message to display in {@link #openSdiWindow} when pop-up could not be opened. */
  private static ERR_MSG_POPUP_BLOCKED =
    "We are unable to open a new window for this link. Please disable any pop-up blockers and try again.";

  /** "cached" value for {@link #getBaseUrl} */
  private baseUrl: string | null = null;

  /** "cached" value for {@link #getLoginUrl} */
  private loginUrl: string | null = null;

  public isSdiCourse(course?: CourseContentModel | null): boolean {
    return this.isSdiCourseFamilyId(course?.familyId);
  }

  public isSdiCourseFamilyId(familyId?: string | undefined): boolean {
    return familyId === constants.familyIdType.CrucialTeams || familyId === constants.familyIdType.SDIAssessment;
  }

  public isSdiAssessmentCourseFamilyId(familyId?: string | undefined): boolean {
    return familyId === constants.familyIdType.SDIAssessment;
  }

  public isSdiCrucialTeamsCourseFamilyId(familyId?: string | undefined): boolean {
    return familyId === constants.familyIdType.CrucialTeams;
  }

  public async isSdiEvent(event?: EventContentModel | null): Promise<boolean> {
    if (event?.learningFormatType === constants.eventType.assessmentOnly) {
      return true;
    } else if (event?.courseId) {
      const course = await CourseApi.getCourse(event.courseId);
      return this.isSdiCourse(course);
    } else {
      return false;
    }
  }

  /**
   * Fetches the "base URL" for the environment-sensitive SDI service
   * instance (e.g., `https://app.dev.corestrengths.com`).
   */
  public async getBaseUrl(forceRefresh = false): Promise<string> {
    if (forceRefresh || !this.baseUrl) {
      this.baseUrl = emptyOrBlankToNull(await EventApi.getSdiBaseUrl());
    }
    if (!this.baseUrl) {
      return Promise.reject(new Error("Unable to fetch sdi_base_url from server"));
    } else {
      return this.baseUrl;
    }
  }

  /**
   * Fetches the "login URL" for the environment-sensitive SDI service
   * instance. This happens to be {@link #getBaseURL()}/login/clp bu
   * we already have the server-side configuration and infrastructure
   * for the full URL in this case so we'll go ahead and use it.
   */
  public async getLoginUrl(forceRefresh = false): Promise<string> {
    if (forceRefresh || !this.loginUrl) {
      this.loginUrl = emptyOrBlankToNull(await EventApi.getSdiLoginUrl());
    }
    if (!this.loginUrl) {
      return Promise.reject(new Error("Unable to fetch sdi_login_url from server"));
    } else {
      return this.loginUrl;
    }
  }

  /** {@link #getBaseURL()}/ */
  public getPlatformUrl(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getBaseUrl()
        .then((base) => {
          resolve(new UrlBuilder(base).path("/").build());
        })
        .catch(reject);
    });
  }

  /** {@link #getBaseURL()}/switch-account */
  public getSwitchAccountUrl(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getBaseUrl()
        .then((base) => {
          resolve(new UrlBuilder(base).path("/switch-account").build());
        })
        .catch(reject);
    });
  }

  /** {@link #getBaseURL()}/account/:sdiAccountId/teams */
  public getAccountTeamsUrl(sdiAccountId: string | number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getBaseUrl()
        .then((base) => {
          resolve(new UrlBuilder(base).path("/account", sdiAccountId, "/teams").build());
        })
        .catch(reject);
    });
  }

  /**
   * Attempt to identify the appropriate {@link #getAccountTeamsUrl}
   * for the specified `platformUser`
   *
   * Looks up the set of distinct `client.sdiAccountId`s for all
   * `clientAssociation`s associated with the specified `platformUser`.
   *  - when there is exactly one, returns {@link #getAccountTeamsUrl},
   *  - when there is more than one, returns {@link #getSwitchAccountUrl}.
   *  - when there are none, returns {@link #getPlatformUrl}.
   */
  public async getAccountTeamsUrlFor(clpPlatformUserId?: string): Promise<string> {
    if (!clpPlatformUserId) {
      return Promise.reject(new Error("No platformUser.id available"));
    } else {
      const sdiAccountIds = await PlatformUserApi.getDistinctAssociatedClientSdiAccountIds(clpPlatformUserId);
      if (sdiAccountIds) {
        if (sdiAccountIds.size === 1) {
          return this.getAccountTeamsUrl(Array.from(sdiAccountIds)[0]);
        } else if (sdiAccountIds.size === 0) {
          return this.getPlatformUrl();
        } else {
          return this.getSwitchAccountUrl();
        }
      } else {
        return Promise.reject(
          new Error(
            "Unable to fetch sdiAccountIds for CLP clients associated with platformUser with id: " + clpPlatformUserId
          )
        );
      }
    }
  }

  /**
   * Attempt to open the given `url` in a new browser window, displaying a
   * user-facing error message if the destination is invalid or the window
   * could not be opened.
   */
  public openSdiWindow(url: string | null | undefined, event?: PointerEvent): boolean {
    if (isEmptyOrBlank(url) || !url) {
      Utils.toastManager.showToast(
        {
          title: "Error",
          body: SdiUtils.ERR_MSG_BAD_LINK,
        },
        "error"
      );
      return false;
    } else {
      const isAuxClick = event != null && (event.type === "auxclick" || event.altKey || event.metaKey);
      const target = isAuxClick ? "_blank" : SdiUtils.WIN_TARGET;
      if (!PopupUtil.open(url, target)) {
        Utils.toastManager.showToast(
          {
            title: "Error",
            body: SdiUtils.ERR_MSG_POPUP_BLOCKED,
          },
          "error"
        );
        return false;
      }
      return true;
    }
  }

  /**
   * like {@link #openSdiWindow} but accepting a Promise-to-be-resolved.
   *
   * @example
   * ```ts
   * SdiUtils.openPromisedSdiWindow(SdiUtils.getAccountTeamsUrlFor(id));
   * // rather than
   * SdiUtils.openSdiWindow(await SdiUtils.getAccountTeamsUrlFor(id));
   * ```
   */
  public openPromisedSdiWindow(promisedUrl: Promise<string>, event?: PointerEvent): void {
    promisedUrl.then((url) => this.openSdiWindow(url, event)).catch(() => this.openSdiWindow(null, event));
  }

  /** show the given error message via "BaseModule/setError" */
  public showError(message: string) {
    store.commit("BaseModule/setError", message);
  }
}

export default new SdiUtils();
