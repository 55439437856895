import {
  BusyEventResponse,
  EventReportResponse,
  EventSearchFilter,
  EventUpdateModel,
  PracticeEventResponse,
  ReclaimLicensesResponse,
  SdiLearnerMeta,
  TimeCardResponse,
} from "@/store/modules/Event/model";
import { EventContentModel, EventDataModel, EventModel, NewNoteModel } from "@cruciallearning/puddle/models/event";
import { http } from "@/services/http";
import routes from "@/services/routes";
import { AxiosResponse } from "axios";
import utils from "@/utils";
import { LoadOptions } from "devextreme/data/load_options";
import constants from "@/utils/constants";
import responses from "@/services/responses";
import auditron from "@/utils/auditron";
import { getInstance } from "@cruciallearning/puddle/auth";
import CodedError from "@/utils/CodedError";
import { PlatformUserModel } from "@/store/modules/User/model";

const Event = {
  createEvent(data: EventDataModel): Promise<Partial<EventContentModel> | null> {
    if (!data.sessionTimeZone.id) {
      data.sessionTimeZone = utils.defaultTimeZone();
    }
    return http
      .post<Partial<EventContentModel>>(`${routes().TRAINING_EVENTS}`, data)
      .then((response) => response.data)
      .catch((error) => {
        let errorMessage = "Failed to create a learning event";
        if (error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        throw new CodedError(errorMessage, error.response.status);
      });
  },

  async getEvent(id: string): Promise<EventContentModel> {
    return utils.normalizeEventDates((await http.get<EventContentModel>(`${routes().TRAINING_EVENTS}/${id}`)).data);
  },
  async getEventsCountForType(type: string): Promise<number | void> {
    return http
      .get<number>(`${routes().TRAINING_EVENTS}/count/${type}`)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
      });
  },

  reclaimLicenses(event: EventContentModel): Promise<string | ReclaimLicensesResponse> {
    return http
      .put<ReclaimLicensesResponse>(`${routes().TRAINING_EVENTS}/${event.id}/reclaim/`)
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        return responses.FAILURE;
      });
  },
  async getEvents(op?: Partial<LoadOptions>): Promise<EventModel> {
    let response;
    if (op) {
      const page = (op.skip as number) / (op.take as number);

      let uri = `${routes().TRAINING_EVENTS}?page=${page}&size=${op.take}`;
      if (op.filter) {
        const opFilter = utils.encode(op.filter[0][2]);
        uri += `&search=${opFilter}`;
      }
      if (op.searchValue) {
        const searchExpression = op.searchValue as EventSearchFilter;
        if (searchExpression.eventType) {
          uri += `&format=${searchExpression.eventType}`;
        }
        if (searchExpression.myEvent) {
          if (searchExpression.myEvent == constants.myEventType.myCreated) {
            uri += "&myCreated=true";
          }
          if (searchExpression.myEvent == constants.myEventType.myRedeemed) {
            uri += "&myRedeemed=true";
          }
        }
      }
      if (op.sort) {
        const overrideSort = op.sort as unknown;
        const sortable = overrideSort as { selector: string; desc: string }[];
        const item = sortable[0];
        if (item) {
          uri += `&sort=${item.selector},${item.desc ? "desc" : "asc"}`;
        }
      }

      if (op.userData) {
        const startDateRange = op.userData["startDateRange"];
        if (startDateRange && startDateRange.length > 0) {
          uri += `&start=${startDateRange}`;
        }
        const endDateRange = op.userData["endDateRange"];
        if (endDateRange && endDateRange.length > 0) {
          uri += `&end=${endDateRange}`;
        }
      }
      response = await http.get<EventModel>(uri);
    } else {
      response = await http.get<EventModel>(routes().TRAINING_EVENTS);
    }
    response.data.content.forEach((e) => utils.normalizeEventDates(e));
    return response.data;
  },

  updateEvent(data: EventUpdateModel, eventId: string): Promise<AxiosResponse> {
    return http.put(`${routes().TRAINING_EVENTS}/${eventId}`, data).catch((error) => {
      let errorMessage = "Failed to update a learning event";
      if (error.response) {
        errorMessage = error.response.data.message;
      }
      throw new CodedError(errorMessage, error.response.status);
    });
  },

  addNote(id: string, note: NewNoteModel): Promise<AxiosResponse> {
    return http.put(`${routes().TRAINING_EVENTS}/${id}/add_note`, {
      note: note.content,
      adminOnly: note.adminOnly,
    });
  },

  deleteEvent(eventId: string): Promise<boolean> {
    return http
      .delete(`${routes().TRAINING_EVENTS}/${eventId}`)
      .then(() => true)
      .catch(() => false);
  },

  getBusyEvents(
    sessionStart: string,
    sessionEnd: string,
    timeZoneOffset: number,
    userIds: string[],
    type: "trainer" | "moderator"
  ): Promise<BusyEventResponse | null> {
    return http
      .post<BusyEventResponse>(`${routes().TRAINING_EVENTS}/busy_time`, {
        sessionStart,
        sessionEnd,
        timeZoneOffset,
        userIds,
        type,
      })
      .then((response) => response.data)
      .catch(() => null);
  },
  async getPracticeEvents(): Promise<EventContentModel[]> {
    return http
      .post<PracticeEventResponse>(`${routes().TRAINING_EVENTS}/get_practice`)
      .then((response) => response.data.courses)
      .catch((err) => {
        console.error("Error occured pulling practice events " + err);
        return [];
      });
  },
  async createPracticeEvent(courseId: string): Promise<EventContentModel | null> {
    return http
      .post<EventContentModel>(`${routes().TRAINING_EVENTS}/create_practice`, { courseId })
      .then((response) => response.data)
      .catch((err) => {
        console.error("Error occured creating practice event " + err);
        return null;
      });
  },
  async createSysCheckEvent(code?: string): Promise<EventContentModel | null> {
    return http
      .post<EventContentModel>(`${routes().TRAINING_EVENTS}/create_system_check`, { code })
      .then((response) => response.data)
      .catch((err) => {
        console.error("Error occured creating system check event " + err);
        return null;
      });
  },
  async getUserEvents(securityId: string): Promise<EventReportResponse | undefined> {
    return http
      .get<EventReportResponse>(`${routes(securityId).USER_EVENTS}`)
      .then((response) => response.data)
      .catch(() => undefined);
  },
  punchTimeCard(eventId: string, sessionId?: number): Promise<boolean> {
    const punchCardRequest = { sessionId: sessionId };
    return http
      .post(routes(eventId).TRAINING_TIME_CARD, punchCardRequest)
      .then(() => true)
      .catch(() => false);
  },
  punchOdTimeCard(eventId: string, courseComplete: boolean, secondsInCourse: number): Promise<boolean> {
    const punchCardOdRequest = { courseComplete: courseComplete, secondsInCourse: secondsInCourse };
    return http
      .post(routes(eventId).TRAINING_OD_TIME_CARD, punchCardOdRequest)
      .then(() => true)
      .catch(() => false);
  },
  async getSessions(id: string): Promise<TimeCardResponse> {
    const url = `${routes().TRAINING_EVENTS}/time_card_sessions/${id}`;
    return http
      .get<TimeCardResponse>(url)
      .then((response) => response.data)
      .catch((err) => {
        console.error("Error occured pulling session data ", err);
        return { userTimeCards: [] };
      });
  },

  async observe(event: EventContentModel): Promise<boolean> {
    const user = getInstance().authUser;
    const tags: string[] = [];
    auditron.addTag(tags, `${user.given_name}.${user.family_name}`);
    auditron.log(event, "EVENT", "observe", { tags: tags });

    return await http
      .post(`${routes().TRAINING_EVENTS}/${event.id}/observe`)
      .then((res) => res.status === 200)
      .catch((err) => {
        console.error("Error observing event ", err);
        return false;
      });
  },
  sdiRegistration(id: string): Promise<string> {
    return http
      .put<string>(`${routes().TRAINING_EVENTS}/sdi/${id}`)
      .then((res) => res.data)
      .catch((err) => {
        console.error("Error registering for sdi event ", err);
        return "";
      });
  },
  getSdiLearnerMetadata(id: string): Promise<SdiLearnerMeta[]> {
    return http
      .get<{ learners: SdiLearnerMeta[] }>(`${routes().TRAINING_EVENTS}/${id}/sdi_status`)
      .then((res) => res.data.learners)
      .catch((err) => {
        console.error("Error pull sdi metadata ", err);
        return [];
      });
  },
  restartSession(eventId: string, sessionId: string): Promise<boolean> {
    return http
      .patch<void>(`${routes().TRAINING_EVENTS}/${eventId}/restart/${sessionId}`)
      .then(() => true)
      .catch(() => false);
  },
  syncSession(eventId: string, sessionId: string): Promise<boolean> {
    return http
      .patch<void>(`${routes().TRAINING_EVENTS}/${eventId}/sync/${sessionId}`)
      .then(() => true)
      .catch(() => false);
  },
  continueSessions(eventId: string): Promise<boolean> {
    return http
      .patch<void>(`${routes().TRAINING_EVENTS}/continue/${eventId}`)
      .then(() => true)
      .catch(() => false);
  },
  getSdiBaseUrl(): Promise<string | null> {
    return http
      .get<string>(`${routes().TRAINING_EVENTS}/sdi_base_url`, { headers: { accept: "text/plain" } })
      .then((res) => res.data)
      .catch((err) => {
        console.error("getSdiBaseUrl failed with error=", err, "returning null");
        return null;
      });
  },
  getSdiLoginUrl(): Promise<string | null> {
    return http
      .get<string>(`${routes().TRAINING_EVENTS}/sdi_login_url`, { headers: { accept: "text/plain" } })
      .then((res) => res.data)
      .catch((err) => {
        console.error("getSdiLoginUrl failed with error=", err, "returning null");
        return null;
      });
  },
  putSdiFacilitator(): Promise<string | null> {
    return http
      .put<string>(`${routes().TRAINING_EVENTS}/sdi/facilitator`)
      .then((res) => res?.data)
      .catch((reason) => {
        console.error("putSdiFacilitator failed with reason=", reason);
        return null;
      });
  },
  getClientFacilitators(salesforceId: string): Promise<PlatformUserModel[]> {
    return http
      .get<PlatformUserModel[]>(`${routes(salesforceId).FACILITATOR_CERTS}`)
      .then((res) => res?.data)
      .catch((reason) => {
        console.error("getClientFacilitators failed, error = ", reason);
        return [];
      });
  },
};
export default Event;
