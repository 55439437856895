import platform from "platform";
import config from "@/config";
import { EventContentModel, EventTimeZone } from "@cruciallearning/puddle/models/event";
import { ClientContentModel } from "@/store/modules/Client/model";
import { CourseContentModel, CourseMetaData, CourseModel } from "@/store/modules/Course/model";
import constants from "./constants";
import api from "@/services/api";
import { DateTime, IANAZone } from "luxon";
import { getInstance } from "@cruciallearning/puddle/auth";
import { ToastManager } from "@cruciallearning/puddle/toast2";

const Utils = {
  millisPerDay: 86400000, // 1000 * 60 * 60 * 24
  icons: {
    CRU: require("@/assets/images/icons/crucial-conversations.svg"),
    GET: require("@/assets/images/icons/gtd.svg"),
    IN_PERSON: "icon-presentation",
    INF: require("@/assets/images/icons/influencer.svg"),
    MATERIALS_ONLY: "icon-notebook",
    THE: require("@/assets/images/icons/power-of-habit.svg"),
    ON_DEMAND: "icon-zap",
    VIRTUAL: "icon-online-education",
  },
  getCookieValue(name: string): string {
    return document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";
  },
  getPlatformAsString(): string {
    const sysInfo = {
      name: platform.name,
      version: platform.version,
      layout: platform.layout,
      os: platform.os,
      description: platform.description,
    };
    return JSON.stringify(sysInfo);
  },
  getRandomID(): string {
    return "_" + Math.random().toString(36).substr(2, 9);
  },
  camelize(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  },
  encode(url: string): string {
    let encodedStr = "";
    const additionalChars = ["(", ")", "&"];
    url = encodeURIComponent(url);

    for (let i = 0, len = url.length; i < len; i++) {
      if (additionalChars.indexOf(url[i]) >= 0) {
        const hex = url.charCodeAt(i).toString(16);
        encodedStr += "%" + hex;
      } else {
        encodedStr += url[i];
      }
    }
    return encodedStr;
  },
  daysRemaining(expires: Date): number {
    if (!expires) return 0;
    const days = Math.ceil((new Date(expires).getTime() - new Date().getTime()) / this.millisPerDay);
    return days >= 0 ? days : 0;
  },
  daysDifference(day1: Date, day2: Date): number {
    if (!day1 || !day2) return 0;
    const days = Math.ceil((new Date(day1).getTime() - new Date(day2).getTime()) / this.millisPerDay);
    return days >= 0 ? days : 0;
  },
  eventTypeName(eventType: string): string {
    switch (eventType) {
      case constants.eventType.materialsOnly:
        return "Materials Only (VIP)";
      case constants.eventType.virtual:
        return "Virtual";
      case constants.eventType.inPerson:
        return "In-Person";
      case constants.eventType.hybrid:
        return "Hybrid";
      case constants.eventType.onDemand:
        return "On-Demand";
      case constants.eventType.practiceVirtual:
        return "Practice Virtual";
      case constants.eventType.assessmentOnly:
        return "Assessment";
      default:
        return "UNKNOWN";
    }
  },
  async updateOdTimeCard(event: EventContentModel, courseMetaData: CourseMetaData): Promise<void> {
    if (event && courseMetaData && this.isOnDemandEvent(event)) {
      const authInstance = getInstance();
      const userId = authInstance.authUser?.id;
      const courseId = courseMetaData?.tiCourseContentId;
      if (userId && courseId) {
        let courseComplete: boolean;
        let secondsInCourse: number;
        try {
          const progress = await api.onDemand.eventProgress(userId, courseId);
          courseComplete = progress.percentComplete === 100;
          secondsInCourse = Number(progress.totalHours) * 60 * 60;
        } catch (e) {
          courseComplete = false;
          secondsInCourse = 0;
          console.log("Error getting progress from TI: " + JSON.stringify(e));
        }
        await api.event.punchOdTimeCard(event.id, courseComplete, secondsInCourse);
        console.log("Punched od timecard, courseComplete: " + courseComplete + ", secondsInCourse: " + secondsInCourse);
      }
    }
  },

  // CHECKING EVENT LEARNING FORMAT TYPE
  isVirtual(type: string) {
    return type === constants.eventType.virtual;
  },
  isVirtualEvent(event: EventContentModel | undefined): boolean {
    if (!event) return false;
    return this.isVirtual(event.learningFormatType);
  },
  isMaterialsOnlyEvent(event: EventContentModel | undefined): boolean {
    if (!event) return false;
    return event.learningFormatType === constants.eventType.materialsOnly;
  },
  isOnDemandEvent(event: EventContentModel | undefined): boolean {
    if (!event) return false;
    return event.learningFormatType === constants.eventType.onDemand;
  },
  isAssessmentOnlyEvent(event: EventContentModel | undefined): boolean {
    if (!event) return false;
    return event.learningFormatType === constants.eventType.assessmentOnly;
  },
  isInPersonEvent(event: EventContentModel | undefined): boolean {
    if (!event) return false;
    return event.learningFormatType === constants.eventType.inPerson;
  },

  // CHECKING *CLIENT* EAP EVENT TYPE ACCESS
  isVirtualClient(client: ClientContentModel | undefined): boolean {
    if (!client) return false;
    return client.eap.indexOf(constants.eventType.virtual) >= 0;
  },
  isMaterialsOnlyClient(client: ClientContentModel | undefined): boolean {
    if (!client) return false;
    return client.eap.indexOf(constants.eventType.materialsOnly) >= 0;
  },
  isOnDemandClient(client: ClientContentModel | undefined): boolean {
    if (!client) return false;
    return client.eap.indexOf(constants.eventType.onDemand) >= 0;
  },
  isInPersonClient(client: ClientContentModel | undefined): boolean {
    if (!client) return false;
    return client.eap.indexOf(constants.eventType.inPerson) >= 0;
  },
  isAssessmentClient(client: ClientContentModel | undefined): boolean {
    if (!client) return false;
    return client.eap.indexOf(constants.eventType.assessmentOnly) >= 0;
  },

  // CHECKING *COURSE* EAP EVENT TYPE ACCESS
  isVirtualCourse(course: CourseContentModel | undefined): boolean {
    if (!course) return false;
    return course.eap.indexOf(constants.eventType.virtual) >= 0;
  },
  isMaterialsOnlyCourse(course: CourseContentModel | undefined): boolean {
    if (!course) return false;
    return course.eap.indexOf(constants.eventType.materialsOnly) >= 0;
  },
  isOnDemandCourse(course: CourseContentModel | undefined): boolean {
    if (!course) return false;
    return course.eap.indexOf(constants.eventType.onDemand) >= 0;
  },
  isInPersonCourse(course: CourseContentModel | undefined): boolean {
    if (!course) return false;
    return course.eap.indexOf(constants.eventType.inPerson) >= 0;
  },

  /** @returns true IFF the given event type should be available in EAP under the currently active configuration */
  isEventTypeEapEnabled(type: string): boolean {
    return config.eap.indexOf(type) >= 0;
  },

  titleCase(title: string): string {
    return title.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()); // Title case
  },

  emptyCourse(): CourseContentModel {
    return {
      id: "",
      includesPhysicalBook: true,
      learningFormatType: "",
      learnerMaterials: [],
      name: "",
      recommendedMaxSeats: 0,
      recommendedSessionCount: 0,
      contentStackId: "",
      contentStackProductId: "",
      order: "",
      eap: [],
      familyName: "",
      familyId: "",
      facilitatedAssessment: false,
    };
  },

  async doSystemCheck(registrationCode?: string): Promise<void> {
    await api.event.createSysCheckEvent(registrationCode).then(async (event) => {
      if (event) {
        window.location.href = `${config.frostUrl}?eventId=${event.id}`;
      }
    });
  },

  defaultTimeZone(): EventTimeZone {
    const now = DateTime.now();
    let offset = "" + now.offset / 60;
    offset += now.offset % 60 > 0 ? ":30" : ":00";
    return {
      id: now.zoneName,
      offset: now.offset / 60,
      title: `(GMT ${offset}) ${now.zoneName.replaceAll("/", " - ")}`,
    };
  },

  normalizeDate(localizedDate: Date): Date {
    try {
      const gmtDate = DateTime.fromISO(localizedDate.toString(), { zone: new IANAZone("GMT") });
      return new Date(gmtDate.year, gmtDate.month - 1, gmtDate.day);
    } catch {
      // The date was already normalized
      return localizedDate;
    }
  },

  normalizeEventDates(event: EventContentModel): EventContentModel {
    event.startDate = this.normalizeDate(event.startDate);
    event.expirationDate = this.normalizeDate(event.expirationDate);
    if (event.registrationCode) {
      const registrationCode = event.registrationCode;
      registrationCode.startDate = this.normalizeDate(registrationCode.startDate);
      registrationCode.endDate = this.normalizeDate(registrationCode.endDate);
      if (registrationCode.registrants) {
        registrationCode.registrants.forEach((e) => {
          e.endDate = this.normalizeDate(e.endDate);
        });
      }
    }
    return event;
  },

  truncateClientId(clientId: string): string {
    if (clientId && clientId.length > 15) return clientId.substring(0, 15);
    return clientId;
  },

  truncate(text: string, length: number, truncateFrom?: string): string {
    if (!truncateFrom) truncateFrom = "end";
    let result = text;

    if (text.length > length) {
      switch (truncateFrom.toLowerCase()) {
        case "start":
          result = "…" + result.slice(text.length - length + 1);
          break;

        case "middle":
          length = length / 2;
          result = result.slice(0, length) + "…" + result.slice(text.length - length + 1);
          break;

        case "end":
          result = result.slice(0, length - 1) + "…";
          break;
      }
    }
    return result;
  },

  fromDate(date?: Date | string): DateTime | undefined {
    if (!date) return undefined;
    let dateTime: DateTime;
    if (date instanceof Date) {
      dateTime = DateTime.fromJSDate(date);
      if (dateTime.isValid) return dateTime;
    }
    dateTime = DateTime.fromISO(date.toString());
    if (dateTime.isValid == null) return dateTime;
    dateTime = DateTime.fromMillis(Date.parse(date.toString()));
    if (dateTime.isValid) return dateTime;
    return undefined;
  },

  dateTimeString(date: Date | DateTime | string, tz = true, utc = false): string {
    return `${this.dateString(date, utc)} ${this.timeString(date, tz, utc)}`;
  },

  dateString(date: Date | DateTime | string, utc = false): string {
    let dateTime = date instanceof DateTime ? date : this.fromDate(date);
    if (!dateTime) return "";
    if (utc) dateTime = dateTime.toUTC(0);
    return dateTime.toFormat("yyyy-MM-dd");
  },

  timeString(date: Date | DateTime | string, tz = true, utc = false): string {
    let dateTime = date instanceof DateTime ? date : this.fromDate(date);
    if (!dateTime) return "";
    if (utc) dateTime = dateTime.toUTC(0);
    let time = dateTime.toFormat("hh:mm a").replaceAll(" AM", " a.m.").replaceAll(" PM", " p.m.");
    if (tz) {
      time += ` ${this.timeZoneString(dateTime)}`;
    }
    return time;
  },

  timeZoneString(date: Date | DateTime): string {
    const dateTime = date instanceof DateTime ? date : this.fromDate(date);
    if (!dateTime) return "";
    return dateTime.toLocal().offsetNameShort;
  },

  timeSpanString(start: Date, end: Date): string {
    return `${this.timeString(start, false)} - ${this.timeString(end)}`;
  },

  composeSessionTime(sessionDate?: string, sessionTime?: string, zoneId?: string): string | undefined {
    const date = this.fromDate(sessionDate);
    const time = this.fromDate(sessionTime);
    if (!date || !time) {
      return undefined;
    }

    let composed = DateTime.now();
    if (zoneId) composed = composed.setZone(zoneId);
    composed = composed.set({
      year: date.year,
      month: date.month,
      day: date.day,
      hour: time.hour,
      minute: time.minute,
      second: time.second,
      millisecond: 0,
    });
    return composed.toUTC().toString();
  },

  isToday(date?: string) {
    let target = this.fromDate(date);
    if (!target) return false;
    if (!(date?.endsWith("Z") || date?.endsWith("+00:00"))) target = target.toUTC(0);
    const now = DateTime.now();
    return target?.year === now.year && target.month === now.month && target.day === now.day;
  },

  isPast(date: string | undefined) {
    const target = this.fromDate(date);
    if (!target) return false;
    const now = DateTime.now();
    if (target.year != now.year) return target.year < now.year;
    if (target.month != now.month) return target.month < now.month;
    if (target.day != now.day) return target.day < now.day;
    if (target.hour != now.hour) return target.hour < now.hour;
    if (target.minute != now.minute) return target.minute < now.minute;
    return target.second < now.second;
  },

  isFuture(date: string | undefined) {
    return !this.isPast(date);
  },

  isValidEmail(email: string): boolean {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  },

  emptyClient(): ClientContentModel {
    return {
      id: "",
      clientName: "",
      externalId: "",
      courseTokens: [],
      eap: [],
      isPublic: false,
      isActive: true,
    };
  },

  eventSubtitle(event: EventContentModel, isHtml = true): string {
    const title = event.title ? event.title : "Event Start " + this.dateString(event.startDate);
    let prefix = "";
    if (event.isPublic) {
      prefix = isHtml ? "PUBLIC&mdash;" : "PUBLIC-";
    }
    return prefix + title;
  },

  isFacilitatedAssessmentCourse(courses: CourseModel, contentStackProductId: string): boolean | undefined {
    const found = courses.content.find((e) => e.contentStackProductId == contentStackProductId);
    return found?.facilitatedAssessment;
  },

  toastManager: new ToastManager(null, {
    ttlMs: "auto",
    canDismiss: true,
    dismissOnClick: true,
    autoDismiss: true,
    suspendAutoDismissOnHover: true,
    deferRemovalMs: 400,
  }),
};
export default Utils;
