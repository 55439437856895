import { http } from "@/services/http";
import routes from "@/services/routes";
import { CertificationMeta, Certification, PlatformUserModel, Certs } from "@/store/modules/User/model";
import responses from "@/services/responses";
import { getInstance } from "@cruciallearning/puddle/auth";
import { default as ClientApi } from "@/services/api/requests/client";
import { ClientContentModel } from "@/store/modules/Client/model";

const PlatformUser = {
  getCertifiedUsers(
    salesForceId: string,
    courseId: string,
    type: "trainers" | "moderators"
  ): Promise<PlatformUserModel[]> {
    return http
      .post<PlatformUserModel[]>(routes("certifications").PLATFORM_USERS, {
        salesForceId,
        courseId,
        type,
      })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 404) console.clear();
        return [];
      });
  },
  getPlatformUser(id: string): Promise<PlatformUserModel | undefined> {
    return http
      .get<PlatformUserModel>(routes(id).PLATFORM_USERS)
      .then((response) => response.data)
      .catch((error) => {
        console.log("platformUser verification: " + error.message);
        return undefined;
      });
  },
  /**
   * Does a full {@link Client#getClientById client lookup} of each
   * `clientAssociation` associated with the specified PlatformUser.
   * @see #getDistinctAssociatedClientSdiAccountIds
   */
  async getAssociatedClients(platformUserId: string): Promise<ClientContentModel[]> {
    const platformUser = await this.getPlatformUser(platformUserId);
    if (!platformUser) {
      return Promise.reject(new Error("Failed to fetch platformUser for id: " + platformUserId));
    } else {
      let result: ClientContentModel[] = [];
      if (platformUser.clientAssociations) {
        for (const clientAssociation of platformUser.clientAssociations) {
          if (clientAssociation?.clientId) {
            const clientContentModels = ClientApi.asClientContentModels(
              await ClientApi.getClientById(clientAssociation.clientId, "externalId")
            );
            if (clientContentModels) {
              result = result.concat(clientContentModels);
            }
          }
        }
      }
      return result;
    }
  },
  /** Extracts distinct `sdiAccountId` values from {@link #getAssociateClients} */
  getDistinctAssociatedClientSdiAccountIds(platformUserId: string): Promise<Set<string>> {
    return new Promise<Set<string>>((resolve, reject) => {
      this.getAssociatedClients(platformUserId)
        .then((clients) => {
          const sdiAccountIds = new Set<string>();
          for (const client of clients ?? []) {
            if (client?.sdiAccountId) {
              sdiAccountIds.add(client.sdiAccountId);
            }
          }
          resolve(sdiAccountIds);
        })
        .catch(reject);
    });
  },
  /** resolves to true IFF {@link #getDistinctAssociatedClientSdiAccountIds} yields a non-empty set */
  hasAssociatedClientSdiAccountIds(platformUserId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.getDistinctAssociatedClientSdiAccountIds(platformUserId)
        .then((sdiAccountIds) => resolve(sdiAccountIds.size > 0))
        .catch(reject);
    });
  },
  createPlatformUser(user: PlatformUserModel, grant: string | null): Promise<string> {
    console.log("platformUser record being created, grant=" + grant);
    let url = routes().PLATFORM_USERS;
    if (grant) {
      url += "?grant=" + grant;
    }
    return http
      .post<string>(url, user)
      .then((response) => response.data)
      .catch((error) => {
        console.log("platformUser record creation failed: " + JSON.stringify(error));
        return responses.FAILURE;
      });
  },
  updateCertification(id: string, certification: Certification): Promise<PlatformUserModel | undefined> {
    return http
      .put<PlatformUserModel>(`${routes().PLATFORM_USERS}${id}`, { certifications: [certification] })
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error update certification " + error);
        return undefined;
      });
  },
  grantTrainerCertification(certifications: Certs): Promise<Certs | undefined> {
    console.log("grantTrainerCertification: " + JSON.stringify(certifications));
    return http
      .post<Certs>(routes().TRAINER_CERTS, certifications)
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error granting certification: " + error);
        return undefined;
      });
  },
  revokeTrainerCertification(certifications: Certs): Promise<Certs | undefined> {
    const auth = getInstance();
    return http
      .delete<Certs>(routes().TRAINER_CERTS, {
        headers: {
          Authorization: `Bearer ${auth.authUser.token}`,
        },
        data: certifications,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error revoking certification: " + error);
        return undefined;
      });
  },
  updateModerator(
    id: string,
    update: {
      moderator?: Certification;
      removeModeratorClient?: string;
      removeAllModerators?: boolean;
    }
  ): Promise<PlatformUserModel | undefined> {
    let body: ModeratorUpdate = {};
    if (update.moderator) {
      body = { moderators: [update.moderator] };
    } else if (update.removeModeratorClient) {
      body = { removeModeratorClient: update.removeModeratorClient };
    } else if (update.removeAllModerators) {
      body = { removeAllModerators: update.removeAllModerators };
    }
    return http
      .put<PlatformUserModel>(`${routes().PLATFORM_USERS}${id}`, body)
      .then((response) => response.data)
      .catch((error) => {
        console.log("Error adding moderator " + error);
        return undefined;
      });
  },
  getCertMetadata(): Promise<CertificationMeta[]> {
    return http
      .post<CertMetaDataResponse>(routes("self_certifications").PLATFORM_USERS)
      .then((response) => response.data.certifications)
      .catch((error) => {
        console.error("Error getting self certification metadata" + error);
        return [];
      });
  },
};

interface ModeratorUpdate {
  moderators?: Certification[];
  removeModeratorClient?: string;
  removeAllModerators?: boolean;
}
interface CertMetaDataResponse {
  certifications: CertificationMeta[];
}
export default PlatformUser;
