<template lang="pug">
div 
  .drawer-menu(:class="heightClass")
    ul
      li(v-for="item in menuItems", @click="onMenuItemClicked")
        router-link(:to="item.route") {{ item.title }}
      li
        a.menu-item(
          v-if="$auth.FACILITATOR",
          style="cursor: pointer",
          @click.stop.prevent="openSdiAccountTeamsLink($event); return false"
        ) SDI
</template>

<script lang="ts">
import { NavigationItem } from "@/store/modules/Base/model";
import { Component, Prop, Vue } from "vue-property-decorator";
import SdiUtils from "@/utils/SdiUtils";

@Component
export default class DrawerMenu extends Vue {
  @Prop({ required: true }) menuItems!: NavigationItem[];
  @Prop({ required: true }) opened!: boolean;

  onMenuItemClicked(selected: string): void {
    this.$emit("selected", { item: selected });
  }

  get heightClass(): string {
    return this.opened ? "drawer-opened" : "drawer-closed";
  }

  openSdiAccountTeamsLink(event?: PointerEvent): void {
    SdiUtils.openPromisedSdiWindow(SdiUtils.getAccountTeamsUrlFor(this.$auth.authUser?.id), event);
  }
}
</script>
<style lang="scss" scoped>
.drawer-menu {
  --drawer-width: 35%;
  background: var(--white);
  width: var(--drawer-width);
  z-index: 2;
  font-size: 1rem;
  position: absolute;
  overflow-y: hidden;
  height: 100%;
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.drawer-menu ul {
  margin-top: 1rem;
}

.drawer-menu li {
  padding-left: 0.5rem;
  padding-bottom: 0.2rem;
  margin: 0 0.5rem;
  line-height: 2rem;
}

.drawer-menu li:hover {
  background-color: var(--info-05);
  cursor: pointer;
}

.drawer-menu li a {
  color: black;
  display: block;
  width: 100%;
}
.drawer-opened {
  border-right: solid 2px var(--gray-20);
  width: var(--drawer-width);
  -webkit-animation: slideDrawerOut linear 0.15s;
  animation: slideDrawerOut linear 0.15s;
}

.drawer-closed {
  width: 0%;
  -webkit-animation: slideDrawerIn linear 0.15s;
  animation: slideDrawerIn linear 0.15s;
}

@-webkit-keyframes slideDrawerOut {
  from {
    width: 0;
  }
  to {
    width: var(--drawer-width);
  }
}
@keyframes slideDrawerOut {
  from {
    width: 0;
  }
  to {
    width: var(--drawer-width);
  }
}
@-webkit-keyframes slideDrawerIn {
  from {
    width: var(--drawer-width);
  }
  to {
    width: 0;
  }
}
@keyframes slideDrawerIn {
  from {
    width: var(--drawer-width);
    padding-top: 0.25rem;
  }
  to {
    width: 0;
  }
}
</style>
